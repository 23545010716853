/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

/* LOGIN SCREEN CSS */

/* General Layout */
.loginScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: var(--background-primary, #0F0F0F);
  color: var(--text-primary, #FFFFFF);
  font-family: var(--font-family-primary, 'Nunito', 'Varela Round', sans-serif);
  overflow: hidden;
  z-index: 1000;
  font-size: calc(14px + 0.390625vw);
  padding: 1rem;
}

/* For backward compatibility */
.splashScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: var(--background-primary, #0F0F0F);
  color: var(--text-primary, #FFFFFF);
  font-family: var(--font-family-primary, 'Nunito', 'Varela Round', sans-serif);
  overflow: hidden;
  z-index: 1000;
  font-size: calc(14px + 0.390625vw);
  padding: 1rem;
}

/* Content Styling */
.loginScreen .content,
.splashScreenContent {
  text-align: center;
  padding: 1rem;
  max-width: 500px;
  width: 90%;
}

/* Logo Text */
.loginScreen .logoText {
  font-size: 3rem;
  font-weight: 900;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  line-height: 1.2;
}

/* Title Section Styling */
.titleSection {
  margin-bottom: 2rem;
  text-align: center;
  padding-top: 1rem;
}

/* Secondary Title (Main Title) */
.loginScreen .mainTitle,
.splashTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 3.5rem;
  color: var(--text-primary, #FFFFFF);
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7), 0 0 3px rgba(255, 255, 255, 0.3);
  margin: 0;
  font-weight: 900;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Animation for gradient shift */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Subtitle Styling */
.loginScreen .superSubtitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Nunito', sans-serif;
  font-size: 1.25rem;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  margin: 0;
  font-style: italic;
  font-weight: 700;
  opacity: 0.9;
}

/* Input Container Styling */
.inputContainer {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

/* Input Field */
.loginScreen .pseudonymInput {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: var(--radius-md, 8px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-primary, #FFFFFF);
  text-align: center;
  outline: none;
  transition: var(--transition-normal, 0.3s ease);
  backdrop-filter: blur(5px);
  margin-bottom: 0.25rem;
}

.loginScreen .pseudonymInput:focus {
  border-color: #f9a8d4 !important; /* pink-300 */
  outline: none;
  box-shadow: 0 0 0 2px rgba(249, 168, 212, 0.2); /* pink-300 with opacity */
}

/* Button Group */
.loginScreen .buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

/* Primary Button Styling with Dynamic Gradient */
.loginScreen .actionButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold, 600);
  color: #1a1a1a;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  border: none;
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.3s ease);
  height: fit-content;
  min-height: 2.75rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  max-width: 200px;
  width: 100%;
  margin: 0.25rem auto;
  display: block;
}

.loginScreen .actionButton:hover {
  transform: translateY(-1px);
  filter: brightness(1.05);
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.15), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
}

/* Secondary Button - "Stay Anonymous" Specifically */
.loginScreen .actionButton.secondary {
  background: transparent;
  color: rgba(255, 255, 255, 0.7);
  border: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0.25rem auto;
  display: block;
  max-width: 200px;
  width: 100%;
}

.loginScreen .actionButton.secondary:hover {
  background: var(--background-secondary, #1A1A1A);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.5);
}

/* Background */
.loginScreen .gridBackground {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle, rgba(249, 168, 212, 0.15) 0%, rgba(0, 0, 0, 0.8) 70%, transparent 100%); /* pink-300 with opacity */
  z-index: -1;
  animation: pulseBackground 8s ease-in-out infinite;
}

@keyframes pulseBackground {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .titleSection {
    margin-bottom: 3rem;
    padding-top: 1.5rem;
  }

  .loginScreen .mainTitle,
  .splashTitle {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .loginScreen .superSubtitle {
    font-size: 1.1rem;
  }

  .loginScreen .pseudonymInput {
    padding: 0.75rem;
  }

  .loginScreen .buttonGroup {
    gap: 0.4rem;
  }
}

@media (max-width: 480px) {
  .titleSection {
    margin-bottom: 2.5rem;
    padding-top: 1rem;
  }

  .loginScreen .mainTitle,
  .splashTitle {
    font-size: 2rem;
  }

  .loginScreen .superSubtitle {
    font-size: 1rem;
  }

  .inputContainer {
    max-width: 280px;
  }

  .loginScreen .pseudonymInput {
    padding: 0.625rem;
  }

  .loginScreen .buttonGroup {
    gap: 0.375rem;
  }
}

/* Error Message */
.error {
  color: var(--error-color, #FF4D4D);
  font-size: 0.875rem;
  margin: 0.5rem 0;
  text-align: center;
}

/* Create Account Text */
.createAccountText {
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  font-size: 0.875rem;
  margin-top: 1.5rem;
  cursor: pointer;
  text-align: center;
}

/* Link Text */
.linkText {
  color: #f9a8d4; /* pink-300 */
  text-decoration: underline;
}

/* Developer Tools Corner - Top right */
.devToolsCorner {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1010;
  display: none; /* Hidden by default */
}

/* Only show on desktop */
@media (min-width: 1024px) {
  .devToolsCorner {
    display: block;
  }
}

.devToolsLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.devToolLink {
  background-color: rgba(80, 80, 180, 0.3);
  color: rgba(180, 180, 255, 0.9);
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  text-decoration: none;
  transition: all 0.2s ease;
  display: block;
  text-align: center;
  white-space: nowrap;
}

.devToolLink:hover {
  background-color: rgba(100, 100, 200, 0.4);
  color: rgba(220, 220, 255, 1);
} 