/* ============= Import Fonts ============= */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700&display=swap');

/* SPLASH SCREEN CSS */

/* General Layout */
.splashScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: var(--background-primary, #0F0F0F);
  color: var(--text-primary, #FFFFFF);
  font-family: var(--font-family-primary, 'Nunito', 'Varela Round', sans-serif);
  overflow: hidden;
  z-index: 1000;
  font-size: calc(14px + 0.390625vw);
  padding: 1rem;
}

/* Content Styling */
.splashScreen .content {
  text-align: center;
  padding: 1rem;
  max-width: 500px;
  width: 90%;
}

/* Logo Text */
.splashScreen .logoText {
  font-size: 3rem;
  font-weight: 900;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 2px;
  line-height: 1.2;
}

/* Title Section Styling */
.titleSection {
  margin-bottom: 2rem;
  text-align: center;
  padding-top: 1rem;
}

/* Secondary Title (Main Title) */
.splashScreen .mainTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 3.5rem;
  color: var(--text-primary, #FFFFFF);
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7), 0 0 3px rgba(255, 255, 255, 0.3);
  margin: 0;
  font-weight: 900;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Animation for gradient shift */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Subtitle Styling */
.splashScreen .superSubtitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Nunito', sans-serif;
  font-size: 1.25rem;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  margin: 0;
  font-style: italic;
  font-weight: 700;
  opacity: 0.9;
}

/* Input Container Styling */
.inputContainer {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  align-items: center;
}

/* Input Field */
.splashScreen .pseudonymInput {
  width: 100%;
  padding: 0.95rem;
  font-size: 0.92rem;
  border-radius: var(--radius-md, 10px);
  border: 1px solid rgba(255, 255, 255, 0.12) !important;
  background: rgba(255, 255, 255, 0.04);
  color: var(--text-primary, #FFFFFF);
  text-align: center;
  outline: none;
  transition: var(--transition-normal, 0.25s ease);
  backdrop-filter: blur(5px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.3px;
}

.splashScreen .pseudonymInput:focus {
  border-color: rgba(249, 168, 212, 0.5) !important; /* pink-300 */
  outline: none;
  box-shadow: 0 0 0 2px rgba(249, 168, 212, 0.15); /* pink-300 with opacity */
  background: rgba(255, 255, 255, 0.06);
}

/* Button Group */
.splashScreen .buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.5rem;
  align-items: center;
}

/* Primary Button Styling with Dynamic Gradient */
.splashScreen .actionButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.9rem 1.5rem;
  font-size: 0.92rem;
  font-weight: var(--font-weight-semibold, 600);
  color: #1a1a1a;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  border: none;
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.25s ease);
  height: fit-content;
  min-height: 2.85rem;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
  max-width: 200px;
  width: 100%;
  margin: 0.25rem auto;
  display: block;
  letter-spacing: 0.4px;
}

.splashScreen .actionButton:hover {
  transform: translateY(-2px);
  filter: brightness(1.05);
  box-shadow: 0 6px 12px -2px rgba(0, 0, 0, 0.15), 0 3px 6px -1px rgba(0, 0, 0, 0.1);
}

/* Secondary Button - "Stay Anonymous" Specifically */
.splashScreen .actionButton.secondary {
  background: transparent;
  color: rgba(255, 255, 255, 0.75);
  border: 1px solid rgba(255, 255, 255, 0.25);
  margin: 0.25rem auto;
  display: block;
  max-width: 200px;
  width: 100%;
}

.splashScreen .actionButton.secondary:hover {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.95);
  border-color: rgba(255, 255, 255, 0.4);
  transform: translateY(-2px);
}

/* Background */
.splashScreen .gridBackground {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle, rgba(249, 168, 212, 0.15) 0%, rgba(0, 0, 0, 0.8) 70%, transparent 100%); /* pink-300 with opacity */
  z-index: -1;
  animation: pulseBackground 10s ease-in-out infinite;
}

@keyframes pulseBackground {
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.03);
  }
  100% {
    opacity: 0.7;
    transform: scale(1);
  }
}

/* Fade Overlay */
.splashScreen .fadeOverlay {
  position: absolute;
  inset: 0;
  background: black;
  opacity: 0.8;
  z-index: 999;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .titleSection {
    margin-bottom: 3rem;
    padding-top: 1.5rem;
  }

  .splashScreen .mainTitle {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .splashScreen .superSubtitle {
    font-size: 1.1rem;
  }

  .splashScreen .pseudonymInput {
    padding: 0.75rem;
  }

  .splashScreen .buttonGroup {
    gap: 0.4rem;
  }
}

@media (max-width: 480px) {
  .titleSection {
    margin-bottom: 2.5rem;
    padding-top: 1rem;
  }

  .splashScreen .mainTitle {
    font-size: 2rem;
  }

  .splashScreen .superSubtitle {
    font-size: 1rem;
  }

  .inputContainer {
    max-width: 280px;
  }

  .splashScreen .pseudonymInput {
    padding: 0.625rem;
  }

  .splashScreen .buttonGroup {
    gap: 0.375rem;
  }
}

/* Logo Container and Image */
.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
}

.introLogo {
  width: 48px !important;
  height: 48px !important;
  object-fit: contain;
  filter: brightness(0.9);
  animation: fadeIn 0.6s ease-out forwards;
  will-change: transform, opacity;
}

/* Add a simple fade in animation instead of zoom */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content.intro {
  height: 100vh;
  width: 100vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Title Container and Logo Styling */
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 1rem;
  width: 100%;
}

.titleLogo {
  width: 32px;
  height: 32px;
  object-fit: contain;
  opacity: 0.9;
}

/* Error Message */
.error {
  color: var(--error-color, #FF4D4D);
  font-size: 0.875rem;
  margin: 0.5rem 0;
  text-align: center;
}

/* Create Account Text */
.createAccountText {
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  font-size: 0.875rem;
  margin-top: 1.5rem;
  cursor: pointer;
  text-align: center;
}

/* Link Text */
.linkText {
  color: rgba(249, 168, 212, 0.9); /* pink-300 slightly transparent */
  text-decoration: none;
  position: relative;
  padding-bottom: 1px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.linkText:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgba(249, 168, 212, 0.5);
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease;
}

.linkText:hover {
  color: rgba(249, 168, 212, 1); /* full opacity on hover */
}

.linkText:hover:after {
  transform: scaleX(1);
}

/* Title */
.title {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 1.75rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  text-align: center;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7), 0 0 2px rgba(255, 255, 255, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Description Text */
.description, .superSubtitle {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  text-align: center;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
}

/* Main Title (Variant for Final Phase) */
.finalPhase .mainTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Rubik', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  text-align: center;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.7), 0 0 2px rgba(255, 255, 255, 0.3);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Animations */
@keyframes glowText {
  from {
    text-shadow: 0 0 5px rgba(249, 168, 212, 0.5); /* pink-300 with opacity */
  }
  to {
    text-shadow: 0 0 15px rgba(249, 168, 212, 0.8); /* pink-300 with opacity */
  }
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logoImage {
  width: 64px;
  height: 64px;
  margin-bottom: 1rem;
  animation: fadeInText 1s ease-out forwards;
}

.gradientText {
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.splashScreen button {
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.waveLine {
  opacity: 0.7;
  animation: waveAnimation 15s linear infinite;
}

.newPromptButton {
  margin-top: 20px;
}

.newPromptButton:hover {
  transform: translateY(-2px);
}

@media (prefers-reduced-motion: reduce) {
  .waveLine {
    animation: none;
  }

  .splashScreen button {
    transition: none;
  }

  .introLogo {
    animation: none;
    opacity: 1;
  }
}

.buttonContainer button {
  margin: 0.5em;
  padding: 0.5em 1em;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .titleContainer {
    margin-bottom: 1rem;
  }

  .titleLogo {
    width: 24px;
    height: 24px;
  }

  .mainTitle {
    font-size: 2rem;
  }

  .superSubtitle {
    font-size: 0.875rem;
  }

  .introLogo {
    width: 48px !important;
    height: 48px !important;
  }
}

@media (max-width: 480px) {
  .titleContainer {
    margin-bottom: 0.75rem;
  }

  .titleLogo {
    width: 20px;
    height: 20px;
  }

  .mainTitle {
    font-size: 1.5rem;
  }

  .superSubtitle {
    font-size: 0.75rem;
  }

  .inputContainer {
    max-width: 260px;
  }

  .introLogo {
    width: 48px !important;
    height: 48px !important;
  }
}

.pseudonymInput {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  border-radius: var(--radius-md, 8px);
}

.pseudonymInput:focus {
  border-color: var(--primary-color, #FF4D4D);
  outline: none;
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb, 255, 77, 77), 0.2);
}

.actionButton {
  border-radius: var(--radius-full, 9999px);
}

.actionButton:hover {
  transform: translateY(-1px);
}

.actionButton.secondary {
  background: transparent;
}

.actionButton.secondary:hover {
  background: var(--background-secondary, #1A1A1A);
}

.centeredLogo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  transform: translateY(-5%); /* Slight offset for visual balance */
}

.splashScreen.closing {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.6s ease-in-out;
  animation: fadeOutScale 0.6s ease-in-out forwards;
}

.splashScreen input,
.splashScreen button {
  -webkit-tap-highlight-color: transparent;
}

/* Developer Tools Panel - Top right corner */
.devToolsPanel {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 14px;
  display: none; /* Hidden by default */
}

/* Only show on desktop */
@media (min-width: 1024px) {
  .devToolsPanel {
    display: block;
  }
}

.devToolsPanel summary {
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}

.devToolsPanel summary:hover {
  color: rgba(255, 255, 255, 0.8);
}

/* Developer Tools Corner - Top right */
.devToolsCorner {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1010;
  display: none; /* Hidden by default */
}

/* Only show on desktop */
@media (min-width: 1024px) {
  .devToolsCorner {
    display: block;
  }
}

.devToolsLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.devToolLink {
  background-color: rgba(80, 80, 180, 0.3);
  color: rgba(180, 180, 255, 0.9);
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  text-decoration: none;
  transition: all 0.2s ease;
  display: block;
  text-align: center;
  white-space: nowrap;
}

.devToolLink:hover {
  background-color: rgba(100, 100, 200, 0.4);
  color: rgba(220, 220, 255, 1);
}

/* Splash Screen Content */
.splashScreenContent {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* App Logo Styling */
.appLogo {
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Header Container */
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

/* Splash Title */
.splashTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Nunito', 'Varela Round', sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Splash Subtitle - Using same style as superSubtitle */
.splashSubtitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Nunito', sans-serif;
  font-size: 1.25rem;
  color: var(--text-secondary, rgba(255, 255, 255, 0.7));
  margin: 0 0 2rem 0;
  font-weight: 500;
  opacity: 0.9;
}

/* Intro Title styling */
.introTitle {
  margin-top: 1rem;
  font-size: clamp(2.5rem, 5vw, 4rem);
}

/* Improve sign-up form responsiveness */
@media (max-width: 768px) {
  .splashSubtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  /* Make form elements more compact on mobile */
  .inputContainer {
    width: 90%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .splashSubtitle {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }
  
  /* Further adjustments for smaller screens */
  .inputContainer {
    width: 90%;
    max-width: 280px;
    gap: 0.5rem;
  }
  
  .pseudonymInput {
    padding: 0.6rem;
    font-size: 0.9rem;
  }
  
  .actionButton {
    min-height: 2.5rem;
    padding: 0.6rem 1.2rem;
  }
}

/* Additional responsiveness for the sign-up form */
form {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

/* Make sure form inputs are responsive */
input[type="text"],
input[type="password"],
input[type="email"] {
  width: 100%;
  box-sizing: border-box;
}

/* Forgot Password Component Styles */
.forgotPasswordContainer {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  padding: 1.5rem 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.forgotPasswordTitle {
  font-family: var(--font-loob, 'Comfortaa'), 'Quicksand', 'Outfit', 'Nunito', 'Varela Round', sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: center;
  color: white;
}

.formDescription {
  font-size: 0.95rem;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.5;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.inputWrapper {
  margin-bottom: 1rem;
}

.emailInput {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border-radius: var(--radius-md, 8px);
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
  background: rgba(255, 255, 255, 0.05);
  color: var(--text-primary, #FFFFFF);
  text-align: center;
  outline: none;
  transition: var(--transition-normal, 0.3s ease);
  backdrop-filter: blur(5px);
}

.emailInput:focus {
  border-color: #f9a8d4 !important; /* pink-300 */
  outline: none;
  box-shadow: 0 0 0 2px rgba(249, 168, 212, 0.2); /* pink-300 with opacity */
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

.sendResetButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold, 600);
  color: #1a1a1a;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  border: none;
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.3s ease);
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}

.sendResetButton:hover:not(:disabled) {
  transform: translateY(-1px);
  filter: brightness(1.05);
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.15), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
}

.sendResetButton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cancelButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold, 600);
  color: rgba(255, 255, 255, 0.7);
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.3s ease);
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}

.cancelButton:hover:not(:disabled) {
  background: rgba(255, 255, 255, 0.05);
  color: rgba(255, 255, 255, 0.9);
  border-color: rgba(255, 255, 255, 0.5);
}

.cancelButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.successContainer {
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.successIcon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: linear-gradient(-45deg, #34d399, #10b981);
  color: white;
  font-size: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.successMessage {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  margin-bottom: 0.5rem;
}

.successSubtext {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 1.5;
  margin-bottom: 1.5rem;
}

.backButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold, 600);
  color: #1a1a1a;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
  border: none;
  border-radius: var(--radius-full, 9999px);
  cursor: pointer;
  transition: var(--transition-normal, 0.3s ease);
  max-width: 200px;
  width: 100%;
  margin: 1rem auto 0;
}

.backButton:hover {
  transform: translateY(-1px);
  filter: brightness(1.05);
  box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.15), 0 3px 5px -1px rgba(0, 0, 0, 0.1);
}

.createAccountText {
  margin-top: 1.25rem;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
}

.linkText {
  color: rgba(249, 168, 212, 0.9); /* pink-300 slightly transparent */
  text-decoration: none;
  position: relative;
  padding-bottom: 1px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.linkText:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgba(249, 168, 212, 0.5);
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s ease;
}

.linkText:hover {
  color: rgba(249, 168, 212, 1); /* full opacity on hover */
}

.linkText:hover:after {
  transform: scaleX(1);
}

@media (max-width: 480px) {
  .forgotPasswordContainer {
    padding: 1rem 0.25rem;
  }
  
  .forgotPasswordTitle {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;
  }
  
  .formDescription {
    font-size: 0.85rem;
    margin-bottom: 1.25rem;
  }
  
  .sendResetButton,
  .cancelButton,
  .backButton {
    padding: 0.6rem 1.25rem;
    font-size: 0.85rem;
  }
}

/* Add a new keyframe animation for a smoother fade out with scale */
@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}

/* Add a transition to the main splash content for smoother animations */
.splashScreenContent {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Make sure transitions are smooth for AnimatePresence */
.splashScreen .inputContainer,
.splashScreen .motion-div {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Add styles for the transition-out phase */
.splashScreen.transitionOut {
  animation: scaleOut 0.5s ease-in-out forwards;
}

@keyframes scaleOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}

/* Enhanced AppSplash animations */
.appSplash {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background-color: var(--background-primary, #0F0F0F);
  color: var(--text-primary, #FFFFFF);
  font-family: var(--font-family-primary, 'Nunito', 'Varela Round', sans-serif);
  overflow: hidden;
  z-index: 1000;
  padding: 1rem;
  will-change: opacity, transform, filter;
}

.appSplash .centeredLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  will-change: opacity, transform, filter;
}

.appSplash .introLogo {
  width: 200px !important;
  height: 200px !important;
  filter: drop-shadow(0 0 20px rgba(249, 168, 212, 0.4));
  will-change: opacity, transform, filter;
}

/* Burner glow animation */
.burnerGlow {
  position: absolute;
  inset: 0;
  background: radial-gradient(circle at center, rgba(255, 154, 158, 0.35) 0%, rgba(0, 0, 0, 0) 70%);
  z-index: -1;
  animation: flamePulse 8s ease-in-out infinite;
}

@keyframes flamePulse {
  0% {
    opacity: 0.3;
    transform: scale(0.97);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.03);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.97);
  }
}
